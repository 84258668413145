var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          "custom-class": "company-info-dialog",
          visible: _vm.visible,
          top: "5vh",
          width: "80%",
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          close: _vm.onCloseDialog,
        },
      },
      _vm.$listeners
    ),
    [
      _vm.showTitle
        ? _c("div", { staticClass: "module-content-top-bar" }, [
            _c("h1", { staticClass: "top-bar-title" }, [
              _vm._v(_vm._s(_vm.title && _vm.title)),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _vm.infoType == "basic"
            ? _c(
                "el-form",
                {
                  ref: "companyInfoForm",
                  staticClass: "company-info-container el-form-item-left-lable",
                  attrs: {
                    rules: _vm.companyInfoRules,
                    model: _vm.companyInfoFormData,
                    "label-width":
                      _vm.$i18n.locale === "cn" ? "160px" : "280px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-block" },
                    [
                      _c("div", { staticClass: "title-bar" }, [
                        _vm._v(_vm._s(_vm.$t("companyBaseInfo"))),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("companyName"),
                            prop: "companyName",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.companyName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "companyName",
                                  $$v
                                )
                              },
                              expression: "companyInfoFormData.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("businessIncorporationNumber"),
                            prop: "businessIncorporationNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.companyInfoFormData
                                  .businessIncorporationNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "businessIncorporationNumber",
                                  $$v
                                )
                              },
                              expression:
                                "companyInfoFormData.businessIncorporationNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Company Abbreviation",
                            prop: "abbreviation",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.companyInfoFormData.abbreviation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "abbreviation",
                                  $$v
                                )
                              },
                              expression: "companyInfoFormData.abbreviation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("incorporationDate"),
                            prop: "incorporationDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "dd-MM-yyyy",
                              "value-format": "yyyy-MM-dd 00:00:01",
                              placeholder: _vm.$t("selectDate"),
                            },
                            model: {
                              value: _vm.companyInfoFormData.incorporationDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "incorporationDate",
                                  $$v
                                )
                              },
                              expression:
                                "companyInfoFormData.incorporationDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("incorporationCountry"),
                            prop: "incorporationCountry",
                          },
                        },
                        [
                          _c("country-selector", {
                            staticStyle: { width: "100%" },
                            model: {
                              value:
                                _vm.companyInfoFormData.incorporationCountry,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "incorporationCountry",
                                  $$v
                                )
                              },
                              expression:
                                "companyInfoFormData.incorporationCountry",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "GST Registration No.",
                            prop: "gstRegistrationNo",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.gstRegistrationNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "gstRegistrationNo",
                                  $$v
                                )
                              },
                              expression:
                                "companyInfoFormData.gstRegistrationNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("streetName"),
                            prop: "streetName",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.streetName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "streetName",
                                  $$v
                                )
                              },
                              expression: "companyInfoFormData.streetName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("buildingName"),
                            prop: "buildingName",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.buildingName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "buildingName",
                                  $$v
                                )
                              },
                              expression: "companyInfoFormData.buildingName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("unitNumber"),
                            prop: "unitNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.unitNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.companyInfoFormData,
                                  "unitNumber",
                                  $$v
                                )
                              },
                              expression: "companyInfoFormData.unitNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("postal"), prop: "postal" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.postal,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoFormData, "postal", $$v)
                              },
                              expression: "companyInfoFormData.postal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("fax"), prop: "fax" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.companyInfoFormData.fax,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoFormData, "fax", $$v)
                              },
                              expression: "companyInfoFormData.fax",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("companyType"),
                            prop: "companyType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.companyInfoFormData.companyType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.companyInfoFormData,
                                    "companyType",
                                    $$v
                                  )
                                },
                                expression: "companyInfoFormData.companyType",
                              },
                            },
                            _vm._l(_vm.companyOptions, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.value, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Physical Supplier",
                            prop: "licenseType",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { padding: "0 12px" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#8182ff",
                                  "active-text": "Yes",
                                  "inactive-text": "No",
                                },
                                on: { change: _vm.licenseTypeChangeHandler },
                                model: {
                                  value: _vm.companyInfoFormData.licenseType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.companyInfoFormData,
                                      "licenseType",
                                      $$v
                                    )
                                  },
                                  expression: "companyInfoFormData.licenseType",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.companyInfoFormData.licenseType
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "License No.",
                                prop: "licenseNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.companyInfoFormData.licenseNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.companyInfoFormData,
                                      "licenseNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "companyInfoFormData.licenseNumber",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-block" },
                    [
                      _c("div", { staticClass: "title-bar" }, [
                        _vm._v(_vm._s(_vm.$t("companyDocument"))),
                      ]),
                      _vm._l(
                        _vm.fileFields.filter(
                          (item) => item !== "otherDocument"
                        ),
                        function (item) {
                          return _c(
                            "el-form-item",
                            {
                              key: item,
                              attrs: { label: _vm.$t(item), prop: item },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: _vm.fileUploadUrl,
                                    accept:
                                      item === "bdnLogo" ? ".jpg,.png" : ".pdf",
                                    limit: 1,
                                    "file-list": _vm.companyInfoFormData[item],
                                    "on-remove": _vm.handleFileRemove(
                                      item,
                                      "single"
                                    ),
                                    "on-success": _vm.handleFileUploadSuccess(
                                      item,
                                      "single"
                                    ),
                                    "before-remove": (file, fileList) =>
                                      _vm.handleBeforeRemove(
                                        file,
                                        fileList,
                                        item
                                      ),
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.getUploadStatus(item)
                                          ? "danger"
                                          : "",
                                        disabled: _vm.getUploadStatus(item),
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getUploadStatus(item)
                                              ? _vm.$t("uploaded")
                                              : _vm.$t("uploadFile")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getUploadStatus(item)
                                              ? _vm.$t("uploadedAndCanDelete")
                                              : _vm.$t("uploadAttachmentPDF")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("otherDocument"),
                            prop: "otherDocument",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: _vm.fileUploadUrl,
                                accept: ".pdf,.jpg,.png",
                                "file-list":
                                  _vm.companyInfoFormData.otherDocument,
                                "on-success": _vm.handleFileUploadSuccess(
                                  "otherDocument",
                                  "multiple"
                                ),
                                "on-remove": _vm.handleFileRemove(
                                  "otherDocument",
                                  "multiple"
                                ),
                                "before-remove": (file, fileList) =>
                                  _vm.handleBeforeRemove(file, fileList),
                                multiple: "",
                              },
                            },
                            [
                              _c("el-button", { attrs: { size: "mini" } }, [
                                _vm._v(_vm._s(_vm.$t("uploadFile"))),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [_vm._v(_vm._s(_vm.$t("uploadAttachmentPDF")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              )
            : _vm.infoType == "bank"
            ? _c(
                "div",
                _vm._l(_vm.bankAccList, function (item, index) {
                  return _c("update-bank", {
                    key: index,
                    attrs: {
                      refKey: index === 0 ? "companyBank" : "chaserBank",
                      category: item.category,
                      sysOrganizationId: _vm.companyInfoFormData.id,
                      companyDefaultBank: _vm.companyDefaultBank,
                      chaserDefaultBank: _vm.chaserDefaultBank,
                      data: item.bankList,
                    },
                  })
                }),
                1
              )
            : _vm.infoType == "settings"
            ? _c(
                "div",
                [
                  _c("update-settings", {
                    attrs: { sysOrganizationId: _vm.companyInfoFormData.id },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.infoType == "basic"
        ? _c(
            "div",
            { staticClass: "flex-end", staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "second-button",
                  on: { click: _vm.onCloseDialog },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "primary-button",
                  attrs: { loading: _vm.submitLoading },
                  on: { click: _vm.submit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }